@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: background 0.6s ease-in;
  max-height: calc(100vh - 80px);
  min-height: 650px;
  height: 95vh;
  padding: 0 $sm-margin;

  @include xxs-screen {
    max-height: calc(100vh - 80px);
  }

  @media only screen and (max-width: $xs-screen + 1px) {
    max-height: calc(100vh - 15px);
  }

  &__container {
    max-width: $page-width;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 450px;
  }

  @media screen and (min-width: 481px) and (max-width: 767px) {
    &__text {
      margin-top: 50px;
      margin-left: 8%;
    }

    &__text-heading, &__text-subheading {
      text-align: left !important;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__right-logo {
    margin-right: 6px;
  }

  &__right-text {
    font-size: var(--s0);
    line-height: 23px;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    &--quote {
      margin: 20px 0 0;
    }

    h2, h4 {
      color: black;
    }

    .fa {
      margin-right: 10px;
    }
  }

  .action-signup {
    z-index: 9;
    font-size: 20px;
    width: 350px;
    color: $white !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;

    &:hover, &:active, &:focus {
      color: $white !important;
    }
  }

  &__subheading-wrapper {
    position: relative;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icons {
    position: relative;
    z-index: 999;
  }

  &__icon {
    color: $yellow;
    font-size: 24px;
  }

  &__text-heading {
    display: table;
    margin: 40px auto 6px;
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    color: $dark-blue;
  }

  &__text-subheading {
    display: table;
    margin: -12px 0 21px;
    font-weight: bold;
    @include subheading(22);

    background: $bg-blue;
    padding: 10px 16px;
    position: relative;
    color: $primary-blue;


    &::before, &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 0;
      border-style: solid;
      border-color: $bg-blue transparent;
      top: 0;
      bottom: 0;
    }
  }

  &__text-sub {
    font-weight: 400;
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: $xs-screen) {
  .header {
    height: 100vh;

    &__container {
      height: auto;
    }

    &__icons {
      top: 12px;
      margin-top: 12px;
    }

    &__icon {
      font-size: 20px;
    }

    &__subheading-wrapper {
      margin: -12px auto 24px;
    }

    &__text-heading {
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      margin: 0;
    }

    &__right {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 215px;
    }

    &__text-subheading {
      @include subheading(16);
      margin: 0;
      text-align: center;
    }

    &__text {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &__text-sub {
      font-size: 14.4px;
    }
  }
}


@media screen and (max-width: 320px) {
  .header {
    &__text-heading {
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 415px) {
  .header {
    &__text {
      &--quote {
        font-size: 1.4em;
      }
    }

    &__scroll {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
    }

    &__scroll-link {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transition: opacity .3s;
      padding-top: 60px;

      &:hover {
        opacity: .5;
      }
    }

    &__scroll-effect {
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 4px solid #333;
      border-bottom: 4px solid #333;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb07 2s infinite;
      animation: sdb07 2s infinite;
      opacity: 0;
      box-sizing: border-box;

      &:nth-of-type(1) {
        top: 16px;
        -webkit-animation-delay: .2s;
        animation-delay: .2s;
      }

      &:nth-of-type(2) {
        top: 32px;
        -webkit-animation-delay: .4s;
        animation-delay: .4s;
      }
    }

    @keyframes sdb07 {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: $mobile-screen) {
  .header {
    background: url(../img/landing-page/mobile-bg.svg) no-repeat center;
    background-size: cover;

    video {
      display: none;
    }

    &__text-heading {
      color: $white;
    }

    &__text {
      margin-top: 50px;
      overflow: hidden;

      &--quote {
        font-size: 1em;
        padding: 0 60px 0 0;
      }
    }
  }
}

@media only screen and (max-width: 375px) and (min-height: 800px) {
  .header {
    &__text {
      margin-top: 100px;
      margin-bottom: 40px;
    }
  }
}

@include short-screen {
  .header {

    &__text {
      margin-top: 15px;
    }

    &__text-subheading {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1400px) and (max-height: 780px) {
  .header {
    &__subheading-wrapper {
      display: none;
    }

    &__right {
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: $xs-screen) and (max-width: $sm-screen) {
  .header {
    max-height: none;
  }
}
