@use "../../settings/all" as *;
@use "../../mixins/all" as *;

$color-sidebar-menu: $dark-blue !default;

.menu {
  &__link {
    font-weight: 700;
    font-size: var(--s0);
    color: $color-sidebar-menu !important;
    display: flex;
    align-items: center;
    gap: 10px;

    &--active {
      @include underline();
      text-decoration-color: $dark-blue;
      text-decoration: underline;
      text-underline-offset: 6px;
      text-decoration-thickness: 2px;
    }

    @include underline();
  }
}

button.menu__link {
  appearance: none;
  background: none;
  border: none;
  font-weight: 700;
  font-size: var(--s0);
  color: $color-sidebar-menu !important;

  .language-code {
    line-height: 1;
  }
}

.nav-menu {
  display: none;
  flex-direction: column;
  gap: 34px;
  padding: 20px;
  margin: 0;
  position: fixed;
  background: white;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: right 0.7s ease-in-out;

  @include mobile-screen {
    display: flex;
  }

  &.open {
    right: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-btn {
    background: transparent;
    font-size: 30px;
    color: $dark-blue;
    border: none;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 0;
  }

  &__nav-link {
    a {
      font-family: $header-font;
      font-weight: 400;
      line-height: 1;
      font-size: 24px;

      &, &:active {
        @include underline();
      }
    }
  }

  &__language-picker {
    display: flex;
    justify-content: space-between;
    padding: 14px 30px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 30px 0px #0000001A;
  }
}
