@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.legacy-page {

  &__sidebar-box {
    margin: 0 20px;
    padding: 20px 0;
    background: $portal-blue;
    color: #fff;
    font-weight: 600;
    font-size: 1.1em;

    .fa-ul {
      margin: 6px 20px;

      i {
        float: left;
      }

      p {
        padding: 0 0 0 26px;
      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__sidebar {
    @media only screen and (min-width: 770px) {
      float: left;
      width: 26%;
      min-width: 240px;
      /*margin: 37px 0 0; fixes the footer pushing when the page is big enough */
      margin-left: 32px;
    }

    @media only screen and (max-width: 769px) {
      float: none;
      width: 100%;
    }
  }
}
