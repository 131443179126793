.snackbar {
  /* Initial state, hidden */
  max-height: 15rem;
  opacity: 0;
  min-width: 250px;
  margin-left: -187px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  left: 50%;
  top: 30px;
  overflow: hidden; /* Ensure the content doesn't overflow during transitions */
  transition: max-height 0.5s ease, opacity 0.5s ease, z-index 5s ease; /* Smooth transition */
  z-index: -1000;
}

.snackbar.show {
  max-height: 15rem; /* Height for visible snackbar */
  opacity: 1; /* Fully visible */
  z-index: 10000;
}

