@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.footnote {
  &__wrapper {
    margin-top: 12px;
  }

  &__icon {
    color: $green;
    margin-right: 4px;
  }

  &__text {
    color: $light-text;
  }

  @include xxs-screen {
    &__wrapper {
      margin-bottom: 40px;
    }
  }
}
