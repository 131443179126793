@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "sass:color";
// the base styling for buttons is done in bootstrap

// mixin that applies the colored style to a btn class (via modifier)
@mixin btn-color($color) {
  color: #ffffff;
  font-weight: 700;
  background-color: $color;
  box-shadow: inset 0 0 0 color.adjust($color, $lightness: -4%),
  0 4px 0 0 color.adjust($color, $lightness: -6%);
  border: 1px solid color.adjust($color, $lightness: -6%);
  border-radius: 1px;
  outline: none;

  @include on-focus {
    background-color: color.adjust($color, $lightness: -3%);
    color: #ffffff;
  }

  &:active {
    box-shadow: 0 2px 0 0 color.adjust($color, $lightness: -6%);
    transform: translateY(4px);
  }
}

@mixin btn-line-invert($color) {
  border: solid 3px $color;
  border-radius: 1px;
  color: $color-text;
  transition: all $fast-duration;
  background: transparent;
  outline: none;

  @include on-focus {
    background-color: $color;
    color: #ffffff;
  }

  @include mobile-screen {
    border: initial !important;
    @include btn-color($color);
  }
}

.btn {
  border-radius: 2px;

  &.btn__round {
    color: $white;
    background: $dark-blue;
    border: 2px solid $white;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: var(--s0);
    padding: 8px 14px;

    &:hover {
      cursor: pointer;
      background: $white;
      color: $dark-blue;
    }

    &--inverse {
      color: $dark-blue;
      background: $white;
      border: 2px solid $dark-blue;

      &:hover {
        cursor: pointer;
        color: $white;
        background: $dark-blue;
      }
    }
  }

  &--transparent {
    color: #ffffff;
  }

  &--blue {
    @include btn-color($portal-blue);
  }

  &--red {
    @include btn-color($red);
  }

  &--green {
    @include btn-color($green);
  }

  // different from the other colored buttons, so no mixin usage here
  &--grey {
    $btn-grey-color: #ececec;
    color: $portal-blue;
    background-color: color.adjust($btn-grey-color, $lightness: 1%);
    box-shadow: inset 0 0 0, // shouldn't there be a color here too?
    0 4px 0 0 color.adjust(#e0e0e0, $lightness: -3%);
    border: solid 1px color.adjust(#e0e0e0, $lightness: -3%);
    border-radius: 1px;
    outline: none;

    @include on-focus {
      background-color: color.adjust($btn-grey-color, $lightness: -3%);
      color: $portal-blue;
    }

    &:active {
      box-shadow: 0 2px 0 0 color.adjust($btn-grey-color, $lightness: -6%);
      transform: translateY(4px);
    }
  }

  &--dark {
    border-radius: 1px;
    color: #ffffff;
    background-color: $portal-blue;

    @include on-focus {
      color: #ff1887;
      background-color: rgba($portal-blue, .7);
    }
  }

  &--light {
    border-radius: 0;
    color: #333333;
    background-color: #ffffff;

    @include on-focus {
      color: #333333;
      background-color: rgba(#ffffff, .8);
    }
  }

  &--line {
    color: $color-text;
    background: transparent;
    border: solid 3px $portal-blue;
    border-radius: 1px;
    transition: all 0.5s;
    outline: none;

    .btn__text {
      display: inline-block;
      position: relative;
      transition: all .5s;

      &:after {
        content: "»";
        font-weight: 500;
        color: $portal-blue;
        position: absolute;
        opacity: 0;
        top: 0;
        right: -10px;
        transition: all .5s;
      }
    }

    @include on-focus {
      .btn__text {
        padding-right: 25px;

        &:after {
          opacity: 1;
          right: 0;
        }
      }
    }

    &--readmore {
      border: none;
      color: color.adjust($portal-blue, $lightness: -6%);

      &:hover, :focus, :active {
        color: color.adjust($portal-blue, $lightness: -6%);
      }
    }
  }

  &--line-grey {
    @extend .btn--line;
    border: solid 3px $color-text;
    width: 150px;

    .btn__text:after {
      color: $color-text;
    }
  }

  &--line-white {
    @extend .btn--line;
    border: solid 3px #ffffff;
    width: 150px;
    color: #ffffff;
    background: transparent;
    font-weight: 700;

    @include on-focus {
      color: #ffffff;
    }

    .btn__text:after {
      color: #ffffff;
    }
  }

  &--line-fullblue {
    @extend .btn--line;
    color: $white;
    background: $portal-blue;
    font-weight: 700;

    @include on-focus {
      color: $portal-blue;
    }

    .btn__text:after {
      color: $white;
    }
  }

  &--line-invert {
    @include btn-line-invert($portal-blue);
  }

  &--line-invert-red {
    @include btn-line-invert($red);
  }

  &--line-invert-blue {
    @include btn-line-invert($portal-blue);
    border-color: $primary-green;

    &:hover {
      border-color: $primary-green;
      background: $primary-green;
    }
  }

  &--line-invert-black {
    @include btn-line-invert($black);
  }


  &--fullwidth {
    width: 100%;
  }
}

