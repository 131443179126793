@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.btn.to-top {
  display: block;
  visibility: hidden;
  position: fixed;
  bottom: 85px;
  left: 5px;
  opacity: 0;
  z-index: 4000;

  transition: all $slow-duration ease-in-out;

  &--visible {
    visibility: visible;
    opacity: 1;
  }

  padding: 10px 12px;
  background: #97C3F6;
  border-radius: 50%;

  i {
    font-size: var(--s1);
  }
}
