@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.role-dropdown {

  &__menu {
    display: block; // override bootstrap
    background-color: rgba(255, 255, 255, 0.8);

    // hides it, while still being able to transform it
    padding: 0;
    max-height: 0;
    overflow: hidden;
    border: none;
    transition: max-height .4s ease-in-out, padding .4s ease-in-out;
    position: relative;
    width: 350px;
    top: auto; //IE fix

    li {
      a:focus {
        background-color: $portal-blue;
      }
    }
  }

  &--open {
    .role-dropdown__menu {
      max-height: 400px;
      padding: 5px 0;
      border: 1px solid rgba(0, 0, 0, .15);
    }
  }

  &--signup {
    width: 375px;
  }
}
