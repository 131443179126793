@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@use "sass:color";

.main-nav {

  &__btn {
    @include xxs-screen {
      padding: 0;
    }

    a {
      font-size: 1.2em;
    }

    &--info {
      padding: 10px 13px !important;
      margin-bottom: 3px;
      color: #fff !important;

      &:hover, &:active, &:focus {
        background-color: color.adjust($portal-blue, $lightness: -4%) !important;
      }
    }

    &--language {
      background: transparent;
      border-radius: 5px;
      display: inline-block;
      margin-top: -2px;
      height: 55px;
      overflow: hidden;
      position: relative;
      transition: all .3s ease;
      z-index: 10;

      & .language-name {
        display: initial;
      }

      & .language-code {
        display: none;
      }

      @include xxs-screen {
        & .language-name {
          display: none;
        }
        & .language-code {
          display: initial;
        }
      }

      &:hover {
        height: 110px;
        border: 1px solid rgba(0, 0, 0, .15);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

        li {
          background-color: white;
        }
      }

      span {
        > * {
          display: inline-block;
        }
      }

      button {
        background: transparent;
        border: 0;
        height: 55px;
      }

      li {
        padding: 0 10px;
        text-align: center;

        @include xxs-screen {
          padding: 0;
        }

        &:hover {
          background-color: #EEE;
        }
      }

    }
  }
}
