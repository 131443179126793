.arrow-shape-container{
  position: relative;
  background:#ffffff;
}
.arrow-shape-top {
  bottom: 100%;
  pointer-events: none;
}
[class*=arrow-shape-] {
  position: absolute;
  width: 100%;
  height: 100px;
  left: 0;
}
.arrow-shape{
  fill: #fff;
  display: block;
  position: relative;
  margin: 0 auto;
}