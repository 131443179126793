@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.breadcrumbs {
  display: flex;
  justify-content: center;
  width: 100%;

  &__container {
    display: flex;
    max-width: $page-width;
    width: 100%;
  }

  &__section {
    display: flex;
    justify-content: center;
    width: 100%;

    @include mobile-screen {
      display: none;
    }

    ul.breadcrumb {
      margin-bottom: 0;
    }
  }

  .breadcrumb {
    background: none;
    opacity: 0.8;
    font-size: var(--s0);
    font-weight: 300;
    line-height: 1.33;
    padding: 0;
    font-family: $header-font;
    color: inherit;

    @include mobile-screen {
      display: none;
    }

    >li+li:before {
      content: ">";
      color: inherit;
    }
  }
}
