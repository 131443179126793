@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.legacy-page {
  display: flex;
  justify-content: center;

  &__container {
    max-width: $page-width;
    width: 100%;
  }

  &__header {
    position: relative;
    margin: 80px 0 0;
    width: 100%;

    background: url(/static/cms/default_header.jpg) center no-repeat scroll;
    background-size: cover;

    &--hex {
      position: relative;
      background: url(/static/cms/auto-pages/hex.svg) center no-repeat;
      background-size: contain;
    }

    &--hex-text {
      position: absolute;
      top: 60px;
      right: 40px;
      left: 40px;
      color: rgba(255, 255, 255, 0.8);
      text-align: justify;
      font-weight: 600;
      text-shadow: 0 1px 1px rgba($black, .1);

      p {
        display: inline;
      }

      i {
        font-size: 1.2em;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &--list {
      position: absolute;
      color: #fff;
    }
  }

  &__video {
    width: 100%;
  }

  &__section {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }

  &__title {
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
  }

  .arrow-shape-container {
    &--autopage-arrow {
      background: transparent;
    }
  }

  &__list-item {
    &--text {
      font-weight: 600;
    }
  }

}

@media only screen and (min-width: $mobile-screen + 1px) {
  .legacy-page {

    &__header {
      height: 450px;

      &--hex {
        margin: 4% 0 3% 60%;
        width: 240px;
        height: 240px;
      }

      &--hex-text {
        p {
          font-size: 1.4em;
        }
      }
    }

    [class*=arrow-shape-] {
      position: relative;
      width: 100%;
      height: 50px;
      left: 0;
    }

  }
}

@media only screen and (max-width: $mobile-screen) {
  .legacy-page {

    &__header {
      height: 300px;

      &--hex {
        margin: 4% auto;
        width: 200px;
        height: 200px;
      }

      &--hex-text {
        p {
          font-size: 1.1em;
        }
      }
    }

    &__list-item {
      &--text {
        font-size: var(--s-1);
      }
    }

  }

  [class*=arrow-shape-] {
    position: relative;
    width: 100%;
    height: 50px;
    left: 0;
  }
}
