@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@include large-screen {
  .legacy-page__searchresults.searchresults-main {
    width: 800px;
  }
}

.legacy-page__searchresults.searchresults-main{
  padding: 0;
  margin: 0 auto;
}
